import * as React from 'react';

import SvgIcon from './svgIcon';

type Props = React.ComponentProps<typeof SvgIcon>;

const IconSwitch = React.forwardRef(function IconSwitch(
  props: Props,
  ref: React.Ref<SVGSVGElement>
) {
  return (
    <SvgIcon {...props} ref={ref}>
      <path d="M3.91001 15.49C3.81147 15.4905 3.71383 15.4712 3.62281 15.4335C3.5318 15.3957 3.44925 15.3401 3.38001 15.27L0.510008 12.39C0.439251 12.3211 0.383015 12.2386 0.344618 12.1476C0.30622 12.0566 0.286438 11.9588 0.286438 11.86C0.286438 11.7612 0.30622 11.6634 0.344618 11.5724C0.383015 11.4814 0.439251 11.3989 0.510008 11.33L3.38001 8.46C3.52218 8.32752 3.71023 8.25539 3.90453 8.25882C4.09883 8.26225 4.28422 8.34096 4.42163 8.47837C4.55904 8.61579 4.63775 8.80117 4.64118 8.99547C4.64461 9.18978 4.57249 9.37782 4.44001 9.52L2.10001 11.86L4.44001 14.21C4.51077 14.2789 4.567 14.3614 4.6054 14.4524C4.6438 14.5434 4.66358 14.6412 4.66358 14.74C4.66358 14.8388 4.6438 14.9366 4.6054 15.0276C4.567 15.1186 4.51077 15.2011 4.44001 15.27C4.37111 15.3406 4.28862 15.3964 4.19753 15.4343C4.10644 15.4721 4.00863 15.491 3.91001 15.49Z" />
      <path d="M15 12.61H1C0.801088 12.61 0.610322 12.531 0.46967 12.3903C0.329018 12.2497 0.25 12.0589 0.25 11.86C0.25 11.6611 0.329018 11.4703 0.46967 11.3297C0.610322 11.189 0.801088 11.11 1 11.11H15C15.1989 11.11 15.3897 11.189 15.5303 11.3297C15.671 11.4703 15.75 11.6611 15.75 11.86C15.75 12.0589 15.671 12.2497 15.5303 12.3903C15.3897 12.531 15.1989 12.61 15 12.61Z" />
      <path d="M12.09 7.87C11.9914 7.87103 11.8936 7.85207 11.8025 7.81426C11.7114 7.77645 11.6289 7.72057 11.56 7.65C11.4892 7.58105 11.433 7.49864 11.3946 7.40761C11.3562 7.31659 11.3364 7.21879 11.3364 7.12C11.3364 7.02121 11.3562 6.92341 11.3946 6.83239C11.433 6.74136 11.4892 6.65895 11.56 6.59L13.9 4.25L11.56 1.9C11.4892 1.83105 11.433 1.74864 11.3946 1.65761C11.3562 1.56659 11.3364 1.46879 11.3364 1.37C11.3364 1.27121 11.3562 1.17341 11.3946 1.08239C11.433 0.99136 11.4892 0.908945 11.56 0.839999C11.7006 0.699548 11.8912 0.620659 12.09 0.620659C12.2887 0.620659 12.4794 0.699548 12.62 0.839999L15.49 3.72C15.6304 3.86062 15.7093 4.05125 15.7093 4.25C15.7093 4.44875 15.6304 4.63937 15.49 4.78L12.62 7.65C12.5508 7.72011 12.4682 7.77569 12.3772 7.81347C12.2862 7.85124 12.1885 7.87046 12.09 7.87Z" />
      <path d="M15 5H1C0.801088 5 0.610322 4.92098 0.46967 4.78033C0.329018 4.63968 0.25 4.44891 0.25 4.25C0.25 4.05109 0.329018 3.86032 0.46967 3.71967C0.610322 3.57902 0.801088 3.5 1 3.5H15C15.1989 3.5 15.3897 3.57902 15.5303 3.71967C15.671 3.86032 15.75 4.05109 15.75 4.25C15.75 4.44891 15.671 4.63968 15.5303 4.78033C15.3897 4.92098 15.1989 5 15 5Z" />
    </SvgIcon>
  );
});

IconSwitch.displayName = 'IconSwitch';

export {IconSwitch};
